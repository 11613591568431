@media (min-width: 768px) {
    .login-input input {
        width: 400px;
        height: 40px;
        margin-bottom: 24px;
        border-radius: 8px;
    }

    .login-input button {
        width: 200px;
        height: 40px;
        color: white;
        background-color: black;
        border: black;
        border-radius: 8px;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;
    }

    .login-input button:hover {
        border: 2px solid black;
        color: black;
        background-color: white;
    }
}

@media (max-width: 767px) {
    .login-input input {
        width: 250px;
        height: 40px;
        margin-bottom: 24px;
        border-radius: 8px;
    }

    .login-input button {
        width: 160px;
        height: 40px;
        color: white;
        background-color: black;
        border: black;
        border-radius: 8px;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .login-input button:hover {
        border: 2px solid black;
        color: black;
        background-color: white;
    }
}