@media (min-width: 768px) {
    .shop-search {
        width: 800px;
        height: 60px;
        font-size: 24px;
        border: 0px;
        border-bottom: 2px solid black;
    }

    .shop-filter {
        width: inherit;
        margin-top: 64px;
        padding-bottom: 8px;
        text-align: right;
        border-bottom: 2px solid gray;
    }

    .shop-filter span {
        margin-left: 8px;
    }

    .shop-product-list .row {
        margin-top: 8px;
        display: flex;
    }

    .product-card {
        width: 300px;
        height: 388px;
        margin: 0px 4px;
        padding: 6px;
        box-shadow: 0 0 0 1px gray inset;
        display: flex;
        flex-direction: column;
    }

    .product-card .image-wrapper {
        width: 300px;
        height: 300px;
        box-shadow: 0 0 0 1px gray inset;
    }

    .product-card .info {
        margin-top: 6px; 
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-grow: 1;
    }

    .product-card .info .name {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
    }

    .product-card .info .price {
        height: fit-content;
        font-size: 16px;
        border: 0px;
        padding: 0px;
    }
}

@media (max-width: 767px) {
    .shop-search {
        width: 240px;
        height: 24px;
        font-size: 14px;
        border: 0px;
        border-bottom: 2px solid black;
    }

    .shop-filter {
        width: inherit;
        margin-top: 16px;
        padding-right: 8px;
        padding-bottom: 4px;
        text-align: right;
        border-bottom: 2px solid gray;
    }

    .shop-filter span {
        font-size: 8px;
        margin-left: 8px;
    }

    .shop-product-list .row {
        margin-top: 8px;
        display: flex;
        justify-content: space-evenly;
    }

    .product-card {
        width: 136px;
        height: 224px;
        padding: 6px;
        box-shadow: 0 0 0 1px gray inset;
        display: flex;
        flex-direction: column;
    }

    .product-card .image-wrapper {
        width: 136px;
        height: 136px;
        box-shadow: 0 0 0 1px gray inset;
    }

    .product-card .info {
        margin-top: 6px; 
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-grow: 1;
    }

    .product-card .info .name {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
    }

    .product-card .info .price {
        height: fit-content;
        font-size: 12px;
        border: 0px;
        padding: 0px;
        background-color: transparent;
    }
}