/* desktop */
@media (min-width: 768px) {
    .container {
        width: auto;
        min-width: 1440px;
        height: fit-content;
    }

    .main-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* mobile */
@media (max-width: 767px) {
    .container {
        width: auto;
        height: fit-content;
    }
}