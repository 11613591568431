@media (min-width: 768px) {
    .product-interface {
        width: 600px;
        height: 600px;
        padding: 16px;
        box-shadow: 0 0 0 1px gray inset;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 16px;
    }

    .product-interface .info {
        padding: 0 8px;
        border-bottom: 2px solid black;
    }

    .product-interface .info .price {
        height: fit-content;
        padding: 0px;
        font-size: 24px;
        border: 0px;
        font-weight: normal;
    }

    .product-interface .interface .amount {
        padding: 0px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .product-interface .interface .amount input {
        width: 16px;
        height: fit-content;
        text-align: center;
    }

    .product-interface .interface .purchase {
        width: 100%;
        height: 64px;
        font-size: 20px;
        color: white;
        font-weight: bold;
        background-color: black;
        border: 2px solid black;
        border-radius: 16px;
    }

    .product-interface .interface .purchase:hover {
        color: black;
        font-weight: bold;
        background-color: white;
        border: 2px solid black;
        border-radius: 16px;
    }
}

@media (max-width: 767px) {
    .product-interface {
        width: inherit;
        padding: 8px;
        border-top: 2px solid black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: sticky;
        bottom: 0px;
        background-color: white;
    }

    .product-interface .info {
        padding: 0 8px;
        border-bottom: 2px solid black;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .product-interface .info .name {
        font-size: 18px;
    }

    .product-interface .info .price {
        height: fit-content;
        background-color: transparent;
        padding: 0px;
        font-size: 12px;
        border: 0px;
        font-weight: normal;
    }

    .product-interface .interface .amount {
        padding: 0px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .product-interface .interface .amount .title {
        font-size: 14px;
    }

    .product-interface .interface .amount input {
        width: 16px;
        height: fit-content;
        text-align: center;
    }

    .product-interface .interface .purchase {
        width: 100%;
        height: 32px;
        font-size: 12px;
        color: white;
        font-weight: bold;
        background-color: black;
        border: 2px solid black;
        border-radius: 8px;
    }

    .product-interface .interface .purchase:hover {
        color: black;
        font-weight: bold;
        background-color: white;
        border: 2px solid black;
        border-radius: 8px;
    }
}