
/* browser */
@media (min-width: 768px) {
    .filter .filter-title {
        width: auto;
        height: 72px;
        padding-left: 16px;
        font-size: 32px;
        background-color: lightgray;
        display: flex;
        align-items: center;
    }

    .filter .filter-body {
        padding: 8px 16px;
    }

    /* filter layout */
    .filter-layout {
        height: 28px;
        display: flex;
        align-items: center;
    }

    .filter-layout .filter-type {
        width: 128px;
        font-weight: bold;
        display: flex;
    }

    .filter-layout .filter-config {
        display: flex;
    }

    .filter-config input {
        margin: 0px;
    }

    .bike-select {
        width: auto;
        height: 68px;
        border-top: 4px solid black;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .bike-select>* {
        width: 50%;
        height: 100%;
        font-size: 32px;
    }

    .bike-result-wrapper {
        display: flex;
        align-items: center;
    }

    /* purchase list */
    .purchase-list {
        border-bottom: 4px solid black;
    }

    .purchase-list-title {
        width: auto;
        height: 72px;
        font-size: 32px;
        padding-left: 16px;
        background-color: lightgray;
        display: flex;
        align-items: center;
    }

    .purchase-list-body {
        padding: 8px 16px;
    }

    /* purchase list layout */
    .purchase-list-layout {
        width: inherit;
        height: 28px;
        display: flex;
        align-items: center;
    }

    .purchase-list-layout .part-kind {
        width: 80px;
        font-weight: bold;
    }

    .purchase-list-layout .part-name {
        width: 50%;
    }

    .purchase-list-layout .part-price {
        width: 20%;
    }

    .purchase-list-layout .part-cancel {
        text-align: right;
        flex-grow: 1;
    }
    
    .price {
        height: 72px;
        font-size: 32px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-top: 4px solid black;
        border-bottom: 4px solid black;
    }

    .final {
        height: 72px;
        border-top: 4px solid black;
        display: flex;
        justify-content: space-between;
    }

    .final>* {
        width: 50%;
        height: 100%;
        font-size: 32px;
    }

    /* product list component */
    .product-search {
        height: 72px;
        display: flex;
    }

    .product-search input {
        width: 484px;
        height: 72px;
        font-size: 24px;
        background-color: lightgray;
        border: 0px;
        padding: 0px;
        padding-left: 8px;
    }

    .product-list {
        height: 1352px;
        overflow-y: scroll;
    }

    .page-number {
        height: 72px;
        font-size: 32px;
        padding-right: 16px;
        background-color: lightgray;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    /* product preview component */
    .preview-wrapper {
        height: 255px;
        border-bottom: 1px solid lightgray;
        display: flex;
        align-items: center;
    }

    .thumbnail {
        width: 238px;
        height: 238px;
        box-shadow: 0 0 0 1px lightgray inset;
        margin-left: 8px;
        background-color: white;
        text-align: center;
        overflow: hidden;
    }

    .thumbnail>img {
        height: inherit;
    }

    .product-explain {
        width: 220px;
        height: 240px;
        margin-left: 8px;
        font-size: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .simple-explain {
        display: -webkit-box;
        text-align: left;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: gray;
        font-size: 12px;
    }
}

/* mobile */
@media (max-width: 767px) {
    /* estimate handler component */
    .estimate-handler-wrapper-fold {
        width: auto;
        height: 40px;
        background-color: black;
    }

    .estimate-handler-wrapper-unfold {
        width: auto;
        height: 400px;
        background-color: black;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: hidden;
    }

    .estimate-handler-button {
        width: auto;
        height: 40px;
        padding: 0px 8px;
        display: flex;
        align-items: center;
    }

    .estimate-handler-content {
        width: auto;
        height: 360px;
        background-color: lightgray;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .product-search input {
        width: 100%;
        height: 40px;
        padding: 0px;
        padding-left: 8px;
        border: 0px;
    }

    .filter-title {
        height: 40px;
        font-size: 24px;
        padding: 0px 16px;
        background-color: gray;
        display: flex;
        align-items: center;
    }

    .filter-body {
        height: fit-content;
        padding: 8px 16px;
    }

    .filter-layout {
        height: 28px;
        display: flex;
        align-items: center;
    }
    
    .filter-layout .filter-type {
        width: 128px;
        font-weight: bold;
    }

    .filter-config input {
        margin: 0px;
    }

    .filter-config .filter-price input {
        width: 48px;
    }

    /* purchase list component */
    .purchase-list-title {
        height: 40px;
        font-size: 24px;
        padding: 0px 16px;
        background-color: gray;
        display: flex;
        align-items: center;
    }

    .purchase-list-body {
        padding: 8px 16px;
    }

    .purchase-list-layout {
        height: 28px;
        display: flex;
        align-items: center;
    }

    .purchase-list-layout .part-kind {
        width: 80px;
        font-weight: bold;
    }

    .purchase-list-layout .part-name {
        width: 50%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .purchase-list-layout .part-price {
        width: 20%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .purchase-list-layout .part-cancel {
        text-align: right;
        flex-grow: 1;
    }

    .price {
        height: 40px;
        font-size: 24px;
        padding-left: 16px;
        background-color: gray;
        display: flex;
        align-items: center;
    }

    /* product preview component */
    .preview-wrapper {
        height: 144px;
        border-bottom: 1px solid lightgray;
        display: flex;
        align-items: center;
    }

    .thumbnail {
        width: 128px;
        height: 128px;
        box-shadow: 0 0 0 1px lightgray inset;
        margin-left: 8px;
        background-color: white;
        text-align: center;
        overflow: hidden;
    }

    .thumbnail>img {
        height: inherit;
    }

    .product-explain {
        width: 60%;
        height: 128px;
        margin-left: 8px;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .simple-explain {
        display: -webkit-box;
        text-align: left;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: gray;
        font-size: 8px;
    }
}