.bike-result {
    width: 100%;
    height: 0px;
    padding-bottom: 59.94764398%;
    position: relative;
    top: 0;
}

.bike-result>div {
    position: absolute;
    text-align: center;
}

.bike-result img {
    height: 100%;
    object-fit: cover;
}

.bike-result>* * {
    z-index: inherit;
}

#set {
    box-shadow: 0 0 0 0px;
}

/* bike parts */

.wheelset {
    width: 100%;
    height: 67.2489082969%;
    top: 32.7510917031%;
    z-index: 0;
    display: flex;
    justify-content: space-between;
}

.front-wheel, .rear-wheel {
    width: 40.3141361257%;
    height: 100%;
    box-shadow: 0 0 0 4px gray inset;
}

.frame {
    width: 62.6963350785%;
    height: 56.5502183406%;
    left: 18.194%;
    top: 17.0306%;
    z-index: 2;
    box-shadow: 0 0 0 4px gray inset;
}

.seatpost {
    width: 5.628%;
    height: 10.48%;
    top: 6.55%;
    z-index: 3;
    box-shadow: 0 0 0 4px gray inset;
}

.saddle {
    width: 17.539%;
    height: 6.55%;
    top: 2%;
    z-index: 4;
    box-shadow: 0 0 0 4px gray inset;
}

.stem {
    width: 8.901%;
    height: 6.332%;
    z-index: 3;
    box-shadow: 0 0 0 4px gray inset;
}

.handlebar {
    width: 9.0314%;
    height: 16.376%;
    z-index: 4;
    box-shadow: 0 0 0 4px gray inset;
}

.chain {
    width: 32.199%;
    height: 20.306%;
    left: 17.277%;
    top: 61.79%;
    z-index: 1;
}

.chainring {
    width: 15.707%;
    height: 26.201%;
    left: 36.649%;
    top: 58.952%;
    z-index: 3;
    box-shadow: 0 0 0 4px gray inset;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crank {
    width: 15.707%;
    height: 15.721%;
    left: 39.791%;
    top: 63.974%;
    z-index: 4;
    box-shadow: 0 0 0 4px gray inset;
}