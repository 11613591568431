/* desktop */
@media (min-width: 768px) {
    .estimate-body {
        width: 1280px;
        height: 1240px;
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
    }

    .status-body {
        width: 764px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .top-status {
        width: auto;
        height: fit-content;
        border: 8px solid black;
        border-radius: 16px;
        overflow: hidden;
    }

    .bottom-status {
        width: auto;
        height: fit-content;
        border: 8px solid black;
        border-radius: 16px;
        overflow: hidden;
    }

    .product-body {
        width: 492px;
        height: auto;
        border: 8px solid black;
        border-radius: 16px;
        overflow: hidden;
    }

    .login * {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .register * {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home {
        width: 1280px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .shop {
        width: 1280px;
        margin-top: 40px;
        text-align: center;
    }

    .shop .location {
        width: inherit;
        text-align: left;
    }

    .product {
        width: 1280px;
        margin-top: 80px;
    }

    .product .main {
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .product .image-wrapper {
        width: 600px;
        height: 600px;
        box-shadow: 0 0 0 1px gray inset;
    }
}

/* mobile */
@media (max-width: 767px) {
    .estimate-body {
        width: auto;
        height: fit-content;
    }

    .sticky-header {
        background-color: white;
        position: sticky;
        top: 0;
    }

    .login * {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .register * {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .shop {
        text-align: center;
    }

    .product .image-wrapper {
        width: 100%;
        aspect-ratio: 1/1;
        box-shadow: 0 0 0 1px gray inset;
    }

    .product .explain {
        height: 1000px;
    }
}