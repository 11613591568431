@media (min-width: 768px) {
    .main-banner {
        width: inherit;
        height: 640px;
        margin-top: 8px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        opacity: 90%;
    }

    .main-banner .main-banner-content {
        text-align: center;
    }

    .main-banner-content h1 {
        color: white;
    }

    .main-banner-content button {
        width: 200px;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 16px;
    }

    .main-banner-content button:hover {
        opacity: 50%;
    }

    .home-product-preview {
        width: 1280px;
        height: fit-content;
        margin-top: 40px;
    }

    .home-product-preview .category-link {
        font-size: 20px;
        font-weight: bold;
    }

    .home-product-preview .category-link:hover {
        opacity: 50%;
    }

    .product-stack {
        margin-top: 8px;
        display: flex;
    }

    .home-product-card {
        width: 312px;
        height: 312px;
        box-shadow: 0 0 0 1px gray inset;
        margin: 0px 4px;
    }
}

@media (max-width: 767px) {
    .main-banner {
        height: 400px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 90%;
    }

    .main-banner .main-banner-content {
        text-align: center;
    }

    .main-banner-content h1 {
        font-size: 24px;
        color: white;
    }

    .main-banner-content button {
        width: 160px;
        height: 32px;
        font-size: 12px;
        font-weight: bold;
        color: white;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 12px;
    }

    .home-product-preview {
        margin-top: 8px;
    }

    .home-product-preview .category-link {
        padding-left: 4px;
        font-size: 16px;
        font-weight: bold;
    }

    .product-stack {
        margin-top: 8px;
        display: flex;
        overflow-x: scroll;
    }

    .home-product-card {
        width: 144px;
        height: 144px;
        box-shadow: 0 0 0 1px gray inset;
        margin: 0px 4px;
        flex-shrink: 0;
    }
}