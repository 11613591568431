/* desktop */
@media (min-width: 768px) {
    .header {
        width: auto;
        height: auto;
    }

    .header .login-menu-wrapper {
        width: auto;
        height: 32px;
        font-size: 16px;
        color: white;
        background-color: black;
        display: flex;
        justify-content: center;
    }

    .header .login-menu {
        width: 1280px;
        display: flex;
        align-items: center;
        justify-content: right;
    }

    .header .login-menu * {
        margin-left: 64px;
    }

    .header .login-menu *:hover {
        opacity: 50%;
    }

    .header .logo {
        margin-top: 80px;
        text-align: center;
    }

    .header .logo:hover {
        opacity: 85%;
    }

    .header .menu-wrapper {
        width: auto;
        height: 64px;
        margin-top: 80px;
        font-size: 24px;
        font-weight: bold;
        color: white;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header .menu-wrapper .menu {
        width: 1280px;
        display: flex;
        justify-content: space-around;
    }

    .header .menu-wrapper .menu *:hover {
        opacity: 50%;
    }

    .adsbygoogle {
        margin-top: 80px;
    }

    .footer {
        width: 100%;
        height: 200px;
        margin-top: 80px;
        font-size: 24px;
        font-weight: bold;
        color: white;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer a:hover {
        opacity: 50%;
    }

    .product-missing {
        width: 100%;
        height: 312px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-weight: bold;
        background-color: lightgray;
        border-radius: 16px;
    }
}

/* mobile */
@media (max-width: 767px) {
    .header {
        width: auto;
        height: auto;
    }   

    .header .login-menu-wrapper {
        height: 20px;
        color: white;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: right;
    }

    .header .login-menu {
        margin-right: 16px;
        display: flex;
        align-items: center;
    }

    .header .login-menu * {
        font-size: 10px;
        margin-left: 16px;
    }

    .header .logo {
        margin-top: 24px;
        text-align: center;
    }

    .header .menu-wrapper {
        height: 40px;
        margin-top: 24px;
        color: white;
        background-color: black;
    }

    .header .menu {
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .header .menu * {
        font-size: 12px;
        font-weight: bold;
    }

    .adsbygoogle {
        margin-top: 8px;
    }

    .footer {
        width: 100%;
        height: 80px;
        margin-top: 8px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .product-missing {
        width: 100%;
        height: 64px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: lightgray;
        border-radius: 8px;
    }
}